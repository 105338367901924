import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Vision from './pages/Vision'
import ErrorPage from './pages/Error'
import Soon from './pages/Soon'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom/dist/umd/react-router-dom.development';
import Success from './pages/Success';
import Referral from './pages/Referral';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  Link,
} from "react-router-dom/dist/umd/react-router-dom.development";
import Affiliates from './pages/Affiliates';
import Signup from './pages/Signup';
import Login from './pages/Login';
import AffiliateDashboard from './pages/AffiliateDashboard';

import {GoogleOAuthProvider} from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <GoogleOAuthProvider clientId='810191037394-u7b1o1dntr7clph1a87b97vkd557u1ct.apps.googleusercontent.com'>

  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App/>}/>
      <Route path="/success" element={<Success/>}/>
      <Route path="/vision" element={<Vision/>}/>
      <Route path="/coming-soon" element={<Soon/>}/>
      <Route path="/affiliates" element={<Affiliates/>}/>
      <Route path="/affiliates/signup" element={<Signup/>}/>
      <Route path="/affiliates/login" element={<Login/>}/>
      <Route path="/affiliates/dashboard" element={<AffiliateDashboard/>}/>
      <Route path="/referral/:referralId" element={<Referral />}/>
          
      <Route path="*" element={<ErrorPage/>}/>
    </Routes>
  </BrowserRouter>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
