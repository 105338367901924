import React, { useState, useEffect, useRef } from 'react';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

import logo from '../assets/sharemate.svg';
import mockup from '../assets/Vision.png';
import axios from 'axios';
import '../App.css';
import discord from "../assets/discord.png";
import x from "../assets/x.png";
import insta from "../assets/insta.png";
import yt from "../assets/yt.png";
import close from "../assets/close.png";
import lightIcon from "../assets/icon.ico";
import darkIcon from "../assets/icon_dark.ico";
import AffiliateLogo from "../assets/AffiliateLogo.png"
import Cookies from 'js-cookie';
import GoogleLogo from '../assets/GoogleLogo.png'


function Signup() {
  const [membersCount, setMembersCount] = useState(10);
  const [overlay, setOverlay] = useState(false);
  const overlayRef = useRef(null);
  const [buttonText, setButtonText] = useState("Stay updated! ");

  const [email, setEmail] = useState('');

  const [price, setPrice] = useState(540)




  const faqs = [
    {
      question: 'What is the hosting process like?',
      answer: 'The hosting process involves setting up your space, creating a listing, and accepting roommates. You will not be charged until transactions commence.',
    },
    {
      question: 'In which countries will Sharemate be available?',
      answer: 'Sharemate will first be available in the United States. We plan on expanding to other countries as soon as possible.',
    },
    {
      question: 'Can I host more than one room at once?',
      answer: 'Yes, you can as many rooms as you\'d like. Additionally, you can host couches and mattresses as long as they are in decent condition.',
    },
    // ... add more FAQs here
  ];

  useEffect(() => {
    const fetchMembersCount = () => {
      axios.get('https://mailer.novexa.app/members-count')
        .then(response => {
          // Assuming the server response contains the count in `memberCount` property
          setMembersCount(response.data.memberCount);
        })
        .catch(error => {
          console.error('There was an error fetching the members count!', error);
        });
    };

    // Fetch the count immediately on component mount and then set up the interval
    fetchMembersCount();
    const intervalId = setInterval(fetchMembersCount, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [membersCount, buttonText, email]);


  useEffect(() => {
    // Function to update favicon based on theme
    const changeFavicon = (theme) => {
      const linkForFavicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
      linkForFavicon.type = 'image/x-icon';
      linkForFavicon.rel = 'shortcut icon';
      linkForFavicon.href = theme === 'dark' ? lightIcon : darkIcon;
      document.getElementsByTagName('head')[0].appendChild(linkForFavicon);
    };

    // Function to update theme
    const updateTheme = () => {
      const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      changeFavicon(theme);
    };

    // Update theme on component mount
    updateTheme();

    // Listen for changes in the prefers-color-scheme setting
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme);

    // Cleanup listener on component unmount
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateTheme);
    };
  }, []);

  const [isOrderButtonDisabled, setIsOrderButtonDisabled] = useState(true);
  const [password, setPassword] = useState(null);
  const [verificationError, setVerificationError] = useState(false);


  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Replace 'http://your-backend-endpoint.com/subscribe' with your actual backend endpoint
      const response = await axios.post('https://mailer.novexa.app/subscribe', { email: email });
      //console.log(response.data);
      // Handle success (clear the input field, show a success message, etc.)
      setEmail('');
      setButtonText('      Email Sent!       ');

      await new Promise(resolve => setTimeout(resolve, 2000));

      setButtonText('Stay updated!')


    } catch (error) {
      console.error('There was an error!', error);
      if (error.code == "ERR_BAD_RESPONSE") {
        setButtonText('You\'re already in!');

        await new Promise(resolve => setTimeout(resolve, 6000));
      }



      setButtonText('Stay updated!')


      // Handle error (show error message)
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch('https://mailer.novexa.app/login', {
        // const response = await fetch('http://localhost:3001/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, password: password}),
      });

      const data = await response.json()

      const accessToken =  data.accessToken;

      
      if (accessToken) {
        Cookies.set("accessToken", accessToken, { domain: 'sharemateapp.com' })
        // const data = await response.json();
        // //console.log('Success:', data);
        // Handle success - perhaps redirecting to a Stripe checkout session or displaying a success message
        Cookies.set("email", email, { domain: 'sharemateapp.com' });
        window.location.href = "/affiliates/dashboard";
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error - the server didn't like the request
        setVerificationError(true)
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error - the fetch call itself failed
      setVerificationError(true)
    }

    

  }

  useEffect(() => {
    //console.log(overlayRef)
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setOverlay(!overlay); // Close the overlay
        //console.log("overlay closed")
      }
    }

    // Step 2: Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Step 4: Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [overlayRef, overlay]); // Dependency array ensures effect is only run on mount and unmount


  const [selectedOption, setSelectedOption] = useState('option1'); // Default value
  const [selectedNumber, setSelectedNumber] = useState(12); // Default value
  const [selectedHiddenNumber, setSelectedHiddenNumber] = useState(12); // Default value
  const [freeMonths, setFreeMonths] = useState(6)
  const [phoneCode, setPhoneCode] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [overlayEmail, setOverlayEmail] = useState(null)
  const [message, setMessage] = useState(null)
  const [isPhoneValid, setIsPhoneValid] = useState(true)

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value)
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value)
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value)
    setIsPhoneValid(isValidPhoneNumber(phoneNumber));
    //console.log(event.target.value)

    if (event.target.value == null || event.target.value == '' ) {
      setIsPhoneValid(true)
    }
  };
  const [isValid, setIsValid] = useState(true);


  const handleOverlayEmailChange = (event) => {
    setOverlayEmail(event.target.value)
    setIsValid(isValidEmail(event.target.value));

    if (event.target.value == null) {
      setIsValid(true)
    }
  };

  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);

    // //console.log(event.target.value)
    // //console.log(selectedOption)

  };

  const handleNumberChange = (event) => {
    //console.log(selectedNumber)
    setSelectedNumber(event.target.value);

    //console.log(event.target.value)
    if (event.target.value == '') {
      setFreeMonths(6)
      setSelectedHiddenNumber(true)
      //console.log("Free :" + freeMonths)
    } else{
      setSelectedHiddenNumber(false)

      setSelectedNumber(event.target.value);
    }

  };

  const login = useGoogleLogin({
    onSuccess: response => handleOnSuccess(response),
    
  })

  const handleOnSuccess = async (response) => {
    const accessToken = response.access_token;
    try {
      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const userInfo = await userInfoResponse.json();
      const email = userInfo.email;
      const firstName = userInfo.given_name;
      const lastName = userInfo.family_name;
      //console.log(userInfo)

      const response = await fetch('https://mailer.novexa.app/google-signup', {
        // const response = await fetch('http://localhost:3001/google-signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, firstName: firstName, lastName: lastName}),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data)

        Cookies.set("accessToken", data.accessToken, { domain: 'sharemateapp.com' });
        // Handle success - perhaps redirecting to a Stripe checkout session or displaying a success message
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error - the server didn't like the request
      }
      // Now you can use the email as needed
      Cookies.set("email", email, { domain: 'sharemateapp.com' });
      window.location.href = "/affiliates/dashboard";
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  useEffect(() => {
    if (phoneCode !== '' && phoneNumber !== null && overlayEmail !== null && firstName !== null && lastName !== null) {
      setIsOrderButtonDisabled(false)
    } else {
      setIsOrderButtonDisabled(true)
    }
    
  }, [phoneCode, phoneNumber, overlayEmail, firstName, lastName])

  useEffect(() => {
    if (selectedOption != "option1") {
      setSelectedNumber(12)
      // //console.log(selectedNumber)
    } else {
      setSelectedNumber(1)
      // //console.log(selectedNumber)
    }
  }, [selectedOption])

  useEffect(() => {
    //console.log("changed")
    if (selectedOption == "option1") {
      setPrice(540 * parseInt(selectedNumber, 10))
      //console.log(true)
      //console.log(selectedNumber)

        if (selectedNumber == 1 || selectedNumber == 2) {

          setFreeMonths(6);
        } else if (selectedNumber == 3) {
          
          setFreeMonths(9);
          //console.log("set")
        } else if (selectedNumber == 4) {
          
          setFreeMonths(12);
        } else if (selectedNumber == 5) {

          setFreeMonths(15);
        }
      // //console.log(price)
    } else {
      setPrice(45 * parseInt(selectedNumber, 10))

      if (selectedHiddenNumber == true) {
        setFreeMonths(6)
      } else if (selectedNumber >= 12 && selectedNumber < 36) {
        setFreeMonths(6)
      } else if (selectedNumber >= 36 && selectedNumber < 48) {
        setFreeMonths(9)
      } else if (selectedNumber >= 48 && selectedNumber < 60) {
        setFreeMonths(12)
      } else if (selectedNumber >= 60) {
        setFreeMonths(15)
      } else if (selectedNumber < 12) {
        setFreeMonths(0)
      } 
      if (!selectedNumber) {
        setPrice(540)
      } 
      // //console.log(price)
      
    }
  }, [selectedNumber, selectedOption])

  const handleOrderSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
  
    try {
      const response = await fetch('http://localhost:3001/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: overlayEmail,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          countryCode: phoneCode,
          price: price
        }),
      });
  
      const sessionData = await response.json();
      
      if (response.ok) {
        //console.log(sessionData)
        // Redirect to Stripe Checkout using the session ID
        const sessionUrl = sessionData.sessionUrl;
        //console.log(sessionUrl)
        window.location.href = sessionUrl;
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error
    }
  };
  

  const handleMessageSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch('http://localhost:3001/message-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, firstName: firstName, lastName: lastName, text: message}),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log('Success:', data);
        // Handle success - perhaps redirecting to a Stripe checkout session or displaying a success message
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error - the server didn't like the request
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error - the fetch call itself failed
    }


  };

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\d+$/;
    return regex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(String(email).toLowerCase());
  };

  return (
    <div className="Signup">
      {overlay ?
        <div className='overlay-bg'>

          <div ref={overlayRef} className='overlay'>
            <div className='x'><img onClick={() => {setOverlay(!overlay)&& (setSelectedNumber(1))}} src={close}/></div>
            <div className='overlay-left'>
              <h2>Thank you for choosing <span className='heading-green'>Sharemate</span></h2>
              <p>Get early access, enjoy full benefits, and get free months when you pre-order.</p>
              {/* <p>See all benefits --></p> */}
            </div>
            <div className='overlay-right'>
              <form className='overlay-form' onSubmit={handleOrderSubmit}>
                <div className='form-name'>
                  <input placeholder="First name*" onChange={handleFirstNameChange} />
                  <input placeholder="Last name*" onChange={handleLastNameChange}/>
                </div>
                <input style={{ border: isValid ? 'initial' : 'solid', borderWidth: isValid ? 'initial' : '.2px', borderColor: isValid ? 'initial' : 'red' }} placeholder="Email address*" onChange={handleOverlayEmailChange}/>
                <div className='overlay-phone'>
                <input className='phone-code' onChange={handlePhoneCodeChange} placeholder="+1" />
                <input style={{ border: isPhoneValid ? 'initial' : 'solid', borderWidth: isPhoneValid ? 'initial' : '.2px', borderColor: isPhoneValid ? 'initial' : 'red' }} className='phone-number' onChange={handlePhoneNumberChange} placeholder="Phone number*" />
                </div>
                <div className='form-time'>
                  <div className='form-time-inputs'>
                    { selectedOption == "option1" ?
                    
                  <select value={selectedNumber} id="simple-dropdown" name="options" onChange={handleNumberChange}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                    :
                    <input value={selectedNumber > 60 ? setSelectedNumber(60) : selectedNumber} id="simple-dropdown" name="options" placeholder='12' onChange={handleNumberChange}>
                      
                    </input>
                  }
                    <select value={selectedOption} id="simple-dropdown" name="options" onChange={handleSelectChange}>
                      <option value="option1">Years</option>
                      <option value="option2">Months</option>
                    </select>
                    {/* <input placeholder="Years" /> */}
                  </div>

                  {price > 539 ? 
                  <div className='overlay-prices'>
                  <p className='old-price'>$100 OFF <span className='old-price-off'>${price} USD</span></p>
                  <div className='form-price'>
        {/* <p>540 USD</p> */}
                  <p className='total-price'>${(price > 539 ? price - 100 : price).toLocaleString()} USD</p>
                  
                  <p className='months-free'>(+{freeMonths} months free*)</p>
                  </div>

                  </div>
                  :<div className='overlay-prices'>
                  <div className='form-price'>
        {/* <p>540 USD</p> */}
                  <p className='total-price'>${(price).toLocaleString()} USD</p>
                  
                  <p className='months-free'>(+{freeMonths} months free*)</p>
                  </div>

                  </div>}
                </div>
                <div className='contribute'>
                
                <p className='donation-prompt'>We are doing a crowdfunding campaign!</p><a href='https://donate.stripe.com/4gw14t5Ya83S38kcMQ' > <p className='heading-green'>Back our project --></p>  </a>
                </div>
                <div className='divider'></div>
                <input style={{ opacity: isOrderButtonDisabled ? 0.5 : 1, cursor: isOrderButtonDisabled ? "default" : "pointer"}} disabled={isOrderButtonDisabled} type='submit' id='submit-order' value='Pre-order' />
                <p className='disclaimer'>*Free from monthly and transactional fees when using our services. Does not include housing costs. Pre-orders are non-refundable.</p>
              </form>
            </div>
          </div>
        </div> : <div></div>}
                <header className="Waitlist-header">
                <a href='/'>
                    <img src={logo} className="Waitlist-logo" alt="logo" />
                </a>
               
            </header>
            <body className='signup-body'>
              
                <div className='signup-box'>
                    <h3>Welcome back!</h3>
                    <div class="signup-box-content">

                    <div className='google-button' onClick={() => login()}>
                    
                        <img className='google-logo' src={GoogleLogo} alt='Google logo' />
                        <p className='google-text'>Log in with Google</p>
                      </div>
                    <div className='or-container'>

                      <div className='or-div'/><p>or</p><div className='or-div'/>
                    </div>
                    <form className='signup-form'>
                        <input onChange={handleEmailChange} placeholder='Email address'></input>
                        <input type='password' onChange={handlePasswordChange} placeholder='Password'></input>
                        <button className='signup-button' onClick={handleLoginSubmit}>Log in</button>
                    </form>
                    <div className='switch-screen'>
                      
                        <p className='switch-q'>Don't have an account?</p>
                        <a className='switch-a' href='/affiliates/signup'><p>Sign up</p></a>
                    </div>
                    </div>
                </div>
                {verificationError ? <div className='error-box'>

<div className='error-box-text'>
  <p>Incorrect email address or password! Having issues?</p>
<a href='https://discord.com/invite/MMFztRAx4s' className='error-click'>Contact us!</a>
</div>


<div className='error-x'><img onClick={() => {setVerificationError(false)}} src={close}/></div></div> : <div></div>}
            </body>
            
        </div>
    );
}

export default Signup;
