import { useState, useEffect } from "react";

function Checkbox({ initialChecked, onCheckedChange, isHost, checkboxHosting, checkboxSearching, toggleCheckboxHosting, toggleCheckboxSearching }) {
    // Initialize checkbox's checked state with a prop
    const [isChecked, setIsChecked] = useState(initialChecked);
  
    
  
    // Effect hook to update local state if the initialChecked prop changes
    useEffect(() => {
      setIsChecked(initialChecked);
    }, [initialChecked]);
  
    // Function to toggle the checkbox's state and notify parent component
    const toggleCheckbox = () => {
      const newState = !isChecked;
      setIsChecked(newState);
      // Call the parent's onCheckedChange function, if provided
      if (onCheckedChange) {
        onCheckedChange(newState);
      }
      // Toggle the appropriate state based on isHost
      if (isHost) {
        toggleCheckboxHosting();
      } else {
        toggleCheckboxSearching();
      }
    };
    let checkboxText;
  
    const checkboxStyle = {
      display: 'flex',
      width: '20px',
      height: '20px',
      backgroundColor: isChecked ? '#2BCA91' : '#f0f0f0', // Change colors here
      cursor: 'pointer',
      borderRadius: '4px',
      // Add more styling as needed
    };
  
    const checkmarkStyle = {
      display: 'block',
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fill: 'white', // Checkmark color
    };
  
    let callback;
  
    if (isHost) {
      checkboxText = "I want to host space to roommates"
      
    } else {
      checkboxText = "I am looking for a place to stay"
    }
  
    return (
      <div className='checkbox-container'>
        <label style={checkboxStyle}>
          <input
            type="checkbox"
            style={{ display: 'none' }}
            // className='checkbox'
            checked={isChecked}
            onChange={toggleCheckbox}
          />
          <svg style={checkmarkStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
            <path d="M20.285,4.708c-0.39-0.391-1.024-0.391-1.414,0L9,14.578L5.121,10.7c-0.391-0.391-1.023-0.391-1.414,0
            s-0.391,1.023,0,1.414l4.593,4.593c0.391,0.391,1.023,0.391,1.414,0l10.571-10.572C20.676,5.732,20.676,5.098,20.285,4.708z"/>
          </svg>
        </label>
          <p className='checkbox-text'>
            {checkboxText}
          </p>
      </div>
    );
  }
  
  // Default props in case they aren't passed by the parent component
  Checkbox.defaultProps = {
    initialChecked: false,
    onCheckedChange: () => {},
  };

  export default Checkbox;