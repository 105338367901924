import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/sharemate.svg';
import mockup from '../assets/Vision.png';
import axios from 'axios';
import '../App.css';
import discord from "../assets/discord.png";
import x from "../assets/x.png";
import insta from "../assets/insta.png";
import yt from "../assets/yt.png";
import close from "../assets/close.png"
import Checkbox from '../components/Checkbox';

import lightIcon from "../assets/icon.ico";
import darkIcon from "../assets/icon_dark.ico";

function Soon() {
  const [membersCount, setMembersCount] = useState(10);
  const [overlay, setOverlay] = useState(false);
  const overlayRef = useRef(null);
  const [buttonText, setButtonText] = useState("Stay updated! ");

  const [email, setEmail] = useState('');

  const [price, setPrice] = useState(540)


  const [checkboxHosting, setCheckboxHosting] = useState(false);
  const [checkboxSearching, setCheckboxSearching] = useState(false);

  useEffect(() => {
    //console.log("isHosting: " + checkboxHosting)
    //console.log("isSearching: " + checkboxSearching)
  }, [checkboxHosting, checkboxSearching])
  // Function to update checkboxHosting state
  const toggleCheckboxHosting = () => {
    setCheckboxHosting(!checkboxHosting);
  };

  // Function to update checkboxSearching state
  const toggleCheckboxSearching = () => {
    setCheckboxSearching(!checkboxSearching);
  };

  const faqs = [
    {
      question: 'What is the hosting process like?',
      answer: 'The hosting process involves setting up your space, creating a listing, and accepting roommates. You will not be charged until transactions commence.',
    },
    {
      question: 'In which countries will Sharemate be available?',
      answer: 'Sharemate will first be available in the United States. We plan on expanding to other countries as soon as possible.',
    },
    {
      question: 'Can I host more than one room at once?',
      answer: 'Yes, you can as many rooms as you\'d like. Additionally, you can host couches and mattresses as long as they are in decent condition.',
    },
    // ... add more FAQs here
  ];

  useEffect(() => {
    const fetchMembersCount = () => {
      axios.get('https://mailer.novexa.app/members-count')
        // axios.get('http://localhost:3001/members-count')
        .then(response => {
          // Assuming the server response contains the count in `memberCount` property
          setMembersCount(response.data.memberCount);
        })
        .catch(error => {
          console.error('There was an error fetching the members count!', error);
        });
    };

    // Fetch the count immediately on component mount and then set up the interval
    fetchMembersCount();
    const intervalId = setInterval(fetchMembersCount, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [membersCount, buttonText, email]);


  useEffect(() => {
    // Function to update favicon based on theme
    const changeFavicon = (theme) => {
      const linkForFavicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
      linkForFavicon.type = 'image/x-icon';
      linkForFavicon.rel = 'shortcut icon';
      linkForFavicon.href = theme === 'dark' ? lightIcon : darkIcon;
      document.getElementsByTagName('head')[0].appendChild(linkForFavicon);
    };

    // Function to update theme
    const updateTheme = () => {
      const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      changeFavicon(theme);
    };

    // Update theme on component mount
    updateTheme();

    // Listen for changes in the prefers-color-scheme setting
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme);

    // Cleanup listener on component unmount
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateTheme);
    };
  }, []);

  const [isOrderButtonDisabled, setIsOrderButtonDisabled] = useState(true);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Replace 'http://your-backend-endpoint.com/subscribe' with your actual backend endpoint
      const response = await axios.post('https://mailer.novexa.app/subscribe', { email: email });
      // const response = await axios.post('http://localhost:3001/subscribe', { email: email });
      //console.log(response.data);
      // Handle success (clear the input field, show a success message, etc.)
      setEmail('');
      setButtonText('      Email Sent!       ');

      await new Promise(resolve => setTimeout(resolve, 2000));

      setButtonText('Stay updated!')


    } catch (error) {
      console.error('There was an error!', error);
      if (error.code == "ERR_BAD_RESPONSE") {
        setButtonText('You\'re already in!');

        await new Promise(resolve => setTimeout(resolve, 6000));
      }



      setButtonText('Stay updated!')


      // Handle error (show error message)
    }
  };

  useEffect(() => {
    //console.log(overlayRef)
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setOverlay(!overlay); // Close the overlay
        //console.log("overlay closed")
      }
    }

    // Step 2: Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Step 4: Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [overlayRef, overlay]); // Dependency array ensures effect is only run on mount and unmount


  const [selectedOption, setSelectedOption] = useState('option1'); // Default value
  const [selectedNumber, setSelectedNumber] = useState(12); // Default value
  const [selectedHiddenNumber, setSelectedHiddenNumber] = useState(12); // Default value
  const [freeMonths, setFreeMonths] = useState(6)
  const [phoneCode, setPhoneCode] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [overlayEmail, setOverlayEmail] = useState(null)
  const [message, setMessage] = useState(null)
  const [isPhoneValid, setIsPhoneValid] = useState(true)

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value)
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value)
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value)
    setIsPhoneValid(isValidPhoneNumber(phoneNumber));
    //console.log(event.target.value)

    if (event.target.value == null || event.target.value == '' ) {
      setIsPhoneValid(true)
    }
  };
  const [isValid, setIsValid] = useState(true);


  const handleOverlayEmailChange = (event) => {
    setOverlayEmail(event.target.value)
    setIsValid(isValidEmail(event.target.value));

    if (event.target.value == null) {
      setIsValid(true)
    }
  };

  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);

    // //console.log(event.target.value)
    // //console.log(selectedOption)

  };

  const handleNumberChange = (event) => {
    //console.log(selectedNumber)
    setSelectedNumber(event.target.value);

    //console.log(event.target.value)
    if (event.target.value == '') {
      setFreeMonths(6)
      setSelectedHiddenNumber(true)
      //console.log("Free :" + freeMonths)
    } else{
      setSelectedHiddenNumber(false)

      setSelectedNumber(event.target.value);
    }

  };

  useEffect(() => {
    if (phoneCode !== '' && phoneNumber !== null && overlayEmail !== null && firstName !== null && lastName !== null) {
      setIsOrderButtonDisabled(false)
    } else {
      setIsOrderButtonDisabled(true)
    }
    
  }, [phoneCode, phoneNumber, overlayEmail, firstName, lastName])

  useEffect(() => {
    if (selectedOption != "option1") {
      setSelectedNumber(12)
      // //console.log(selectedNumber)
    } else {
      setSelectedNumber(1)
      // //console.log(selectedNumber)
    }
  }, [selectedOption])

  const [priceOff, setPriceOff] = useState(200)


  useEffect(() => {
    if (selectedOption == "option1") {
      setPrice(540 * parseInt(selectedNumber, 10))

      

        if (selectedNumber == 1 || selectedNumber == 2) {

          setFreeMonths(6);
          setPriceOff(200)
        } else if (selectedNumber == 3) {
          
          setFreeMonths(9);
          setPriceOff(300)
          //("set")
        } else if (selectedNumber == 4) {
          
          setFreeMonths(12);
          setPriceOff(400)
        } else if (selectedNumber == 5) {

          setFreeMonths(15);
          setPriceOff(750)
        }
    } else {
      setPrice(45 * parseInt(selectedNumber, 10))

      

      if (selectedHiddenNumber == true) {
        setFreeMonths(6)
        setPriceOff(200)
      } else if (selectedNumber >= 12 && selectedNumber < 36) {
        setFreeMonths(6)
        setPriceOff(200)
      } else if (selectedNumber >= 36 && selectedNumber < 48) {
        setFreeMonths(9)
        setPriceOff(300)
      } else if (selectedNumber >= 48 && selectedNumber < 60) {
        setFreeMonths(12)
        setPriceOff(400)
      } else if (selectedNumber >= 60) {
        setFreeMonths(15)
        setPriceOff(750)
      } else if (selectedNumber < 12) {
        setFreeMonths(0)
        setPriceOff(200)
      } 
      if (!selectedNumber) {
        setPrice(540)
      } 
      
    }

    

    
  }, [selectedNumber, selectedOption])

  const handleOrderSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
  
    try {
      const response = await fetch('https://mailer.novexa.app/create-checkout-session', {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: overlayEmail,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          countryCode: phoneCode,
          price: price,
          isHosting: checkboxHosting,
          isSearching: checkboxSearching,
        }),
      });
  
      const sessionData = await response.json();
      
      if (response.ok) {
        //console.log(sessionData)
        // Redirect to Stripe Checkout using the session ID
        const sessionUrl = sessionData.sessionUrl;
        //console.log(sessionUrl)
        window.location.href = sessionUrl;
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error
    }
  };
  

  const handleMessageSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch('http://localhost:3001/message-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, firstName: firstName, lastName: lastName, text: message}),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log('Success:', data);
        // Handle success - perhaps redirecting to a Stripe checkout session or displaying a success message
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error - the server didn't like the request
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error - the fetch call itself failed
    }


  };

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\d+$/;
    return regex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(String(email).toLowerCase());
  };

  return (
    <div className="Waitlist">
      {overlay ?
        <div className='overlay-bg'>

          <div ref={overlayRef} className='overlay'>
            <div className='x'><img onClick={() => {setOverlay(!overlay)&& (setSelectedNumber(1))}} src={close}/></div>
            <div className='overlay-left'>
              <div className='overlay-left-top'>

              <h2>Thank you for choosing <span className='heading-green'>Sharemate</span></h2>
              <p>Get early access, access all features, and get free months when you pre-order.</p>
              </div>
              <div className='contribute'>
                
                <p className='donation-prompt'>We are also receiving donations!</p><a href='https://donate.stripe.com/4gw14t5Ya83S38kcMQ' > <p className='heading-green'>Back our project --></p>  </a>
                </div>
              {/* <a>See all benefits</a> */}
            </div>
            <div className='overlay-right'>
              <form className='overlay-form' onSubmit={handleOrderSubmit}>
                <div className='form-name'>
                  <input placeholder="First name*" onChange={handleFirstNameChange} />
                  <input placeholder="Last name*" onChange={handleLastNameChange}/>
                </div>
                <input style={{ border: isValid ? 'initial' : 'solid', borderWidth: isValid ? 'initial' : '.2px', borderColor: isValid ? 'initial' : 'red' }} placeholder="Email address*" onChange={handleOverlayEmailChange}/>
                <div className='overlay-phone'>
                <input className='phone-code' onChange={handlePhoneCodeChange} placeholder="+1" />
                <input style={{ border: isPhoneValid ? 'initial' : 'solid', borderWidth: isPhoneValid ? 'initial' : '.2px', borderColor: isPhoneValid ? 'initial' : 'initial' }} className='phone-number' onChange={handlePhoneNumberChange} placeholder="Phone number*" />
                </div>
                <div className='form-time'>
                  <div className='form-time-inputs'>
                    { selectedOption == "option1" ?
                    
                  <select value={selectedNumber} id="simple-dropdown" name="options" onChange={handleNumberChange}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                    :
                    <input value={selectedNumber > 60 ? setSelectedNumber(60) : selectedNumber} id="simple-dropdown" name="options" placeholder='12' onChange={handleNumberChange}>
                      
                    </input>
                  }
                    <select value={selectedOption} id="simple-dropdown" name="options" onChange={handleSelectChange}>
                      <option value="option1">Years </option>
                      <option value="option2">Months</option>
                      
                    </select>
                    {/* <input placeholder="Years" /> */}
                  </div>

                  {price > 539 ? 
                  <div className='overlay-prices'>
                  <p className='old-price'>${priceOff} OFF <span className='old-price-off'>${price} USD</span></p>
                  <div className='form-price'>
        {/* <p>540 USD</p> */}
                  <p className='total-price'>${(price > 539 ? price - priceOff : price).toLocaleString()} USD</p>
                  
                  <p className='months-free'>(+{freeMonths} months free*)</p>
                  </div>

                  </div>
                  :<div className='overlay-prices'>
                  <div className='form-price'>
        {/* <p>540 USD</p> */}
                  <p className='total-price'>${(price).toLocaleString()} USD</p>
                  
                  <p className='months-free'>(+{freeMonths} months free*)</p>
                  </div>

                  </div>}
                  
                </div>
                <div className='overlay-checkboxes'>
                <Checkbox isHost={true} toggleCheckboxHosting={toggleCheckboxHosting}/>
                <Checkbox isHost={false} toggleCheckboxSearching={toggleCheckboxSearching}/>

                </div>
                
                <div className='divider'></div>
                <input style={{ opacity: isOrderButtonDisabled ? 0.5 : 1, cursor: isOrderButtonDisabled ? "default" : "pointer"}} disabled={isOrderButtonDisabled} type='submit' id='submit-order' value='Pre-order now' />
                <p className='disclaimer'>*Free from monthly and transactional fees when using our services. Does not include housing costs. Pre-orders are non-refundable.</p>
              </form>
            </div>
          </div>
        </div> : <></>}
                <header className="Waitlist-header">
                <a href='/'>
                    <img src={logo} className="Waitlist-logo" alt="logo" />
                </a>
                <div className='header-links'>
                    <a href='/vision'><p>Vision</p></a>
                    <a href='/affiliates'><p>Affiliates</p></a>
                    {/* <a href='/coming-soon'><p>Roadmap</p></a> */}
                    <a href='https://docs.google.com/document/d/1gLYdYDB7G8xBEsFhLlo1TCfG7071bqzyS_ldhCCVJbc/edit?usp=sharing'><p>White Paper</p></a>

                    <a href='https://donate.stripe.com/4gw14t5Ya83S38kcMQ'><p>Donate</p></a>
                </div>
                <div className='header-right'>

                    <button className='header-button' onClick={() => setOverlay(!overlay)}>Pre-order</button>
                </div>
            </header>
            <body className='soon-body'>
            <div />
        <h1>Coming Soon</h1>
        <p>Sign up for updates and be the first to access this page!</p>
        <form className='Waitlist-newsletter' onSubmit={handleSubmit}>
          <input
            type='email'
            id='email'
            name='email'
            placeholder='Enter your email address'
            value={email}
            onChange={handleEmailChange}
            required
          />
          <input type='submit' id='submit' value={buttonText} />
        </form>
        <div />

            </body>
            <footer className='Waitlist-footer'>
            <div className='footer-top-div'/>

                <div className='Waitlist-footer-top'>
                    <div className='footer-logo'>
                            <img src={logo} className="Waitlist-logo" alt="logo" />
                    </div>
                    <div className='waitlist-footer-links'>

                        <section className='link-column'>
                            <h3>Discover</h3>
                            <p><a href='/vision'>Vision</a></p>
                            <p><a href='/coming-soon'>Roadmap</a></p>
                            <p><a href='/affiliates'>Affiliates</a></p>
                            <p><a href='/coming-soon'>About us</a></p>
                        </section>
                        <section className='link-column'>
                            <h3>Community</h3>
                            <p><a href='https://discord.gg/MMFztRAx4s'>Join us on Discord</a></p>
                            <p><a href='https://discord.gg/jr2RzpSduP'>Ambassadors</a></p>
                            <p><a href='https://discord.gg/pr527aXuRM'>Affiliate club</a></p>
                            <p><a href='/coming-soon'>Forums</a></p>
                        </section>
                        <section className='link-column'>
                            <h3>Help</h3>
                            <p><a href='/coming-soon'>Learn Sharemate</a></p>
                            <p><a href='https://discord.com/invite/MMFztRAx4s'>Request a feature</a></p>
                          <p><a href='https://discord.com/invite/MMFztRAx4s'>Submit feedback</a></p>
                          <p><a href='https://discord.com/invite/MMFztRAx4s'>Report a bug</a></p>
                          <p><a href='https://discord.com/invite/MMFztRAx4s'>Support</a></p>
                        </section>
                        <section className='link-column'>
                            <h3>Policies</h3>
                            <p><a href="https://docs.google.com/document/d/1fc04Swmr1peTqOkI3DkkpfNB6he5j8KVarCFTwtj_Sc/edit?usp=sharing">Terms of Service</a></p>
                            <p><a href="https://docs.google.com/document/d/1iF3LC5JIqyU57Nh6qppZETFCw_inxn4ePgYCbzNpyug/edit?usp=sharing">Privacy Policy</a></p>
                            <p><a href='/coming-soon'>Brand Guidelines</a></p>
                            <p><a href='/coming-soon'>Licenses</a></p>
                        </section>
                    </div>
                </div>
                <div className='footer-div'/>
                <div className='Waitlist-footer-bottom'>
                    <div className='copyright-notice'>
                        <p>&copy; 2024 Novexa. All Rights Reserved.</p>
                    </div>
                    <div className='socials'>
                    <a href="https://discord.gg/8ZFnnuWrpQ"><img src={discord} /></a>
                    <a href="https://twitter.com/SharemateApp"><img src={x} /></a>
                    <a href="https://www.instagram.com/sharemateapp"><img src={insta} /></a>
                    <a href="https://www.youtube.com/channel/UC2omiezKZ7OI7xjBWlZjZoA"><img src={yt} /></a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Soon;
