import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import App from '../App'
import ErrorPage from './Error';

const Referral = () => {
    const [isValid, setIsValid ] = useState(true)
//   const history = useHistory();
    const [userCode, setUserCode] = useState(null)

  useEffect(() => {
    // Call your function here
    const path = window.location.pathname;
    //console.log(path)


    // Assuming the URL is something like "/referral/somecode"
    // Split the path by '/' and get the segment after "/referral"
    const segments = path.split('/');
    const referralIndex = segments.findIndex(segment => segment === 'referral');
    
    
    // Ensure that 'referral' segment is not the last part of the path
    if (referralIndex >= 0 && referralIndex < segments.length - 1) {
        const referralCode = segments[referralIndex + 1];
        // Now you can use referralCode as needed
        //console.log(referralCode); // Logs: somecode
        setUserCode(referralCode)
        handleReferral(referralCode);
        //console.log("Referral page code before prop: " + userCode)

        
    } 

    // Redirect to the root route
    // history.push('/');+
  }, [userCode]); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    console.log("isValid: " + isValid)
  }, [isValid])

async function handleReferral(code) {
    // Your referral handling logic here
        //console.log('Handle the referral logic with code:', code);
  
        try {
          // const response = await fetch('http://localhost:3001/check-referral', {
          const response = await fetch('https://mailer.novexa.app/check-referral', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              linkName: code,
            }),
          });
      
          const referralTest = await response.json();
          //console.log(referralTest)

          if (referralTest.isValid == true) {
            //console.log(true)
            setIsValid(true)

          } else {
            //console.log(false)

            setIsValid(false)
          }
          
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          // Handle network error
        }
      };

    

  return (
    isValid ?
    <App referralCode={userCode}/> : <ErrorPage/>
  ); // This component doesn't render anything
};

export default Referral;