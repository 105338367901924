import React, { useState, useEffect, useRef } from 'react';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import logo from '../assets/sharemate.svg';
import mockup from '../assets/Vision.png';
import axios from 'axios';
import '../App.css';
import discord from "../assets/discord.png";
import x from "../assets/x.png";
import insta from "../assets/insta.png";
import yt from "../assets/yt.png";
import close from "../assets/close.png";
import lightIcon from "../assets/icon.ico";
import darkIcon from "../assets/icon_dark.ico";
import AffiliateLogo from "../assets/AffiliateLogo.png"
import Cookies from 'js-cookie';
import GoogleLogo from '../assets/GoogleLogo.png'


function Signup() {
  const [membersCount, setMembersCount] = useState(10);
  const [overlay, setOverlay] = useState(false);
  const [verifyScreen, setVerifyScreen] = useState(false);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const overlayRef = useRef(null);
  const [buttonText, setButtonText] = useState("Stay updated! ");
  const [isValid, setIsValid] = useState(true);

  
  const [email, setEmail] = useState('');
  const [verificationError, setVerificationError] = useState(false);

  const [freeMonths, setFreeMonths] = useState(6)
  const [phoneCode, setPhoneCode] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [overlayEmail, setOverlayEmail] = useState(null)
  const [message, setMessage] = useState(null)
  const [isPhoneValid, setIsPhoneValid] = useState(true)

  const [passwordMeetsRequirements, setPasswordMeetsRequirements] = useState(true)
  const [passwordsMatch, setPasswordsMatch] = useState(true)


  const handleOnSuccess = async (response) => {
    const accessToken = response.access_token;
    try {
      const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const userInfo = await userInfoResponse.json();
      const email = userInfo.email;
      const firstName = userInfo.given_name;
      const lastName = userInfo.family_name;
      //console.log(userInfo)

    const response = await fetch('https://mailer.novexa.app/google-signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, firstName: firstName, lastName: lastName}),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log('Success:', data);
        // Handle success - perhaps redirecting to a Stripe checkout session or displaying a success message
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error - the server didn't like the request
      }
      // Now you can use the email as needed
      Cookies.set("email", email, { domain: 'sharemateapp.com' });
      window.location.href = "/affiliates/dashboard";
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };
    
  const login = useGoogleLogin({
    onSuccess: response => handleOnSuccess(response),
    
  })
  const [price, setPrice] = useState(540)


  const onSuccess = (response) => {
    //console.log('Login Success:', response);
    // Send token to backend for verification
  };
  
  const onFailure = (response) => {
    //console.log('Login Failed:', response);
  };
  

  const faqs = [
    {
      question: 'What is the hosting process like?',
      answer: 'The hosting process involves setting up your space, creating a listing, and accepting roommates. You will not be charged until transactions commence.',
    },
    {
      question: 'In which countries will Sharemate be available?',
      answer: 'Sharemate will first be available in the United States. We plan on expanding to other countries as soon as possible.',
    },
    {
      question: 'Can I host more than one room at once?',
      answer: 'Yes, you can as many rooms as you\'d like. Additionally, you can host couches and mattresses as long as they are in decent condition.',
    },
    // ... add more FAQs here
  ];

  useEffect(() => {
    const fetchMembersCount = () => {
      axios.get('https://mailer.novexa.app/members-count')
        // axios.get('http://localhost:3001/members-count')
        .then(response => {
          // Assuming the server response contains the count in `memberCount` property
          setMembersCount(response.data.memberCount);
        })
        .catch(error => {
          console.error('There was an error fetching the members count!', error);
        });
    };

    // Fetch the count immediately on component mount and then set up the interval
    fetchMembersCount();
    const intervalId = setInterval(fetchMembersCount, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [membersCount, buttonText, email, verificationError]);
  const [disableSignupButton, setDisableSignupButton] = useState(true)


  useEffect(() => {
    // Function to update favicon based on theme
    const changeFavicon = (theme) => {
      const linkForFavicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
      linkForFavicon.type = 'image/x-icon';
      linkForFavicon.rel = 'shortcut icon';
      linkForFavicon.href = theme === 'dark' ? lightIcon : darkIcon;
      document.getElementsByTagName('head')[0].appendChild(linkForFavicon);
    };

    // Function to update theme
    const updateTheme = () => {
      const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      changeFavicon(theme);
    };

    // Update theme on component mount
    updateTheme();

    // Listen for changes in the prefers-color-scheme setting
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme);

    if (password != null && firstName && lastName && email && isValid && confirmPassword && passwordsMatch && passwordMeetsRequirements) {
      setDisableSignupButton(false)
      
    }

    // Cleanup listener on component unmount
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateTheme);
    };
  }, [password, firstName, lastName, email, isValid, confirmPassword, passwordsMatch, passwordMeetsRequirements]);

  const [isOrderButtonDisabled, setIsOrderButtonDisabled] = useState(true);

  const handleSignupSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch('https://mailer.novexa.app/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, firstName: firstName, lastName: lastName, password: password}),
      });

      if (response.ok) {
        // const data = await response.json();
        // //console.log('Success:', data);

        // Cookies.set("email", email, { domain: 'localhost' });
        setVerifyScreen(true)
        // Handle success - perhaps redirecting to a Stripe checkout session or displaying a success message
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error - the server didn't like the request
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error - the fetch call itself failed
    }


    


  };

  const handleVerificationSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch('https://mailer.novexa.app/check-verification-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, firstName: firstName, lastName: lastName, password: password, code: verificationCode}),
      });

      const data = await response.json();

      
      
      if (response.ok) {
        // Handle success - perhaps redirecting to a Stripe checkout session or displaying a success message
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error - the server didn't like the request
      }
      
      if (data.code == verificationCode) {
        
        const accessToken = data.accessToken;
        Cookies.set("accessToken", accessToken, { domain: 'sharemateapp.com' })
        Cookies.set("email", email, { domain: 'sharemateapp.com' });
        window.location.href = "/affiliates/dashboard";
      } else {
        //console.log(response.code, verificationCode)
        setVerificationError(true)
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error - the fetch call itself failed
    }


    


  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  

  function validatePassword(password) {
    const hasMinLength = password.length >= 12;
    const hasTwoNumbers = (password.match(/\d/g) || []).length >= 2;
    const hasTwoSpecialChars = (password.match(/[^a-zA-Z0-9]/g) || []).length >= 2;
    
    return hasMinLength && hasTwoNumbers && hasTwoSpecialChars;
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (!validatePassword(event.target.value)) {
      // Password doesn't meet the criteria
      // Show an error message or handle as needed
      setPasswordMeetsRequirements(false)
    } else {
      // Password meets the criteria
      // Proceed with your logic (e.g., update state, enable submission)
      setPasswordMeetsRequirements(true)

    }
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (event.target.value == password) {
      setPasswordsMatch(true);
    } else {
      setPasswordsMatch(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Replace 'http://your-backend-endpoint.com/subscribe' with your actual backend endpoint
      const response = await axios.post('https://mailer.novexa.app/subscribe', { email: email });
      // const response = await axios.post('http://localhost:3001/subscribe', { email: email });
      //console.log(response.data);
      // Handle success (clear the input field, show a success message, etc.)
      setEmail('');
      setButtonText('      Email Sent!       ');

      await new Promise(resolve => setTimeout(resolve, 2000));

      setButtonText('Stay updated!')


    } catch (error) {
      console.error('There was an error!', error);
      if (error.code == "ERR_BAD_RESPONSE") {
        setButtonText('You\'re already in!');

        await new Promise(resolve => setTimeout(resolve, 6000));
      }



      setButtonText('Stay updated!')


      // Handle error (show error message)
    }
  };

  useEffect(() => {
    //console.log(overlayRef)
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setOverlay(!overlay); // Close the overlay
        //console.log("overlay closed")
      }
    }

    // Step 2: Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Step 4: Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [overlayRef, overlay]); // Dependency array ensures effect is only run on mount and unmount


  const [selectedOption, setSelectedOption] = useState('option1'); // Default value
  const [selectedNumber, setSelectedNumber] = useState(12); // Default value
  const [selectedHiddenNumber, setSelectedHiddenNumber] = useState(12); // Default value
  

  const [code, setCode] = useState(Array(6).fill('')); // Replace 4 with the number of digits you need
  const [verificationCode, setVerificationCode] = useState(null);

  useEffect(() => {
    // This effect runs whenever the `code` array changes.
    // It combines the array into a string and then converts it to an integer.
    const currentCode = code.join('');
    if (/^\d{6}$/.test(currentCode)) { // Check if all entries are digits and the code is complete
      setVerificationCode(parseInt(currentCode, 10));
      //console.log(currentCode)
    }
  }, [code]); // Dependency array, re-run effect when `code` changes

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^\d$/.test(value) || value === '') {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Focus next input on entry
      if (element.nextSibling && value) {
        element.nextSibling.focus();
      }
    }
  };

  const handleKeyUp = (element, index) => {
    if (element.keyCode === 8 && !element.value && index !== 0) {
      // Focus previous input on backspace if current is empty
      element.target.previousSibling.focus();
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value)
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value)
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value)
    setIsPhoneValid(isValidPhoneNumber(phoneNumber));
    //console.log(event.target.value)

    if (event.target.value == null || event.target.value == '' ) {
      setIsPhoneValid(true)
    }
  };


  const handleOverlayEmailChange = (event) => {
    setOverlayEmail(event.target.value)
    setEmail(event.target.value)
    setIsValid(isValidEmail(event.target.value));

    if (event.target.value == null) {
      setIsValid(true)
    }
  };

  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);

    // //console.log(event.target.value)
    // //console.log(selectedOption)

  };

  const handleNumberChange = (event) => {
    //console.log(selectedNumber)
    setSelectedNumber(event.target.value);

    //console.log(event.target.value)
    if (event.target.value == '') {
      setFreeMonths(6)
      setSelectedHiddenNumber(true)
      //console.log("Free :" + freeMonths)
    } else{
      setSelectedHiddenNumber(false)

      setSelectedNumber(event.target.value);
    }

  };

  useEffect(() => {
    if (phoneCode !== '' && phoneNumber !== null && overlayEmail !== null && firstName !== null && lastName !== null) {
      setIsOrderButtonDisabled(false)
    } else {
      setIsOrderButtonDisabled(true)
    }
    
  }, [phoneCode, phoneNumber, overlayEmail, firstName, lastName])

  useEffect(() => {
    if (selectedOption != "option1") {
      setSelectedNumber(12)
      // //console.log(selectedNumber)
    } else {
      setSelectedNumber(1)
      // //console.log(selectedNumber)
    }
  }, [selectedOption])

  useEffect(() => {
    //console.log("changed")
    if (selectedOption == "option1") {
      setPrice(540 * parseInt(selectedNumber, 10))
      //console.log(true)
      //console.log(selectedNumber)

        if (selectedNumber == 1 || selectedNumber == 2) {

          setFreeMonths(6);
        } else if (selectedNumber == 3) {
          
          setFreeMonths(9);
          //console.log("set")
        } else if (selectedNumber == 4) {
          
          setFreeMonths(12);
        } else if (selectedNumber == 5) {

          setFreeMonths(15);
        }
      // //console.log(price)
    } else {
      setPrice(45 * parseInt(selectedNumber, 10))

      if (selectedHiddenNumber == true) {
        setFreeMonths(6)
      } else if (selectedNumber >= 12 && selectedNumber < 36) {
        setFreeMonths(6)
      } else if (selectedNumber >= 36 && selectedNumber < 48) {
        setFreeMonths(9)
      } else if (selectedNumber >= 48 && selectedNumber < 60) {
        setFreeMonths(12)
      } else if (selectedNumber >= 60) {
        setFreeMonths(15)
      } else if (selectedNumber < 12) {
        setFreeMonths(0)
      } 
      if (!selectedNumber) {
        setPrice(540)
      } 
      // //console.log(price)
      
    }
  }, [selectedNumber, selectedOption])

  const handleOrderSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
  
    try {
      const response = await fetch('http://localhost:3001/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: overlayEmail,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          countryCode: phoneCode,
          price: price
        }),
      });
  
      const sessionData = await response.json();
      
      if (response.ok) {
        //console.log(sessionData)
        // Redirect to Stripe Checkout using the session ID
        const sessionUrl = sessionData.sessionUrl;
        //console.log(sessionUrl)
        window.location.href = sessionUrl;
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error
    }
  };
  

  const handleMessageSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch('http://localhost:3001/message-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, firstName: firstName, lastName: lastName, text: message}),
      });

      if (response.ok) {
        const data = await response.json();
        //console.log('Success:', data);
        // Handle success - perhaps redirecting to a Stripe checkout session or displaying a success message
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error - the server didn't like the request
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error - the fetch call itself failed
    }


  };

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\d+$/;
    return regex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(String(email).toLowerCase());
  };



  return (
    <div className="Signup">
      
                <header className="Waitlist-header">
                <a href='/'>
                    <img src={logo} className="Waitlist-logo" alt="logo" />
                </a>
               
            </header>
            <body className='signup-body'>
              
                  {verifyScreen ? <div className='signup-box'>

                    <h3>Enter your verification code</h3>
                    <div>
        {code.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(e.target, index)}
            onKeyUp={(e) => handleKeyUp(e, index)}
            style={{ height: '2rem', width: '2rem', textAlign: 'center', marginRight: '0.5rem', backgroundColor: '#151515', border: "1px solid #848484", borderRadius: "4px", color: "white", opacity: ".7"}}
          />
        ))}
      </div>
                    <div className='signup-box-content'>

                      <div className='or-container-full'>
                        <div className='or-div-full'/>
                        </div> 
                      <form className='signup-form' onSubmit={handleVerificationSubmit}>

                          <button className='signup-button'>Continue</button>
                      </form>
                      <div className='switch-screen'>
                          <p className='switch-q'>Didn't receive your code?</p>
                          <a href='/affiliates/login'><p className='switch-a'>Resend code</p></a>
                      </div>
                    </div>
                </div>
                  : <div className='signup-box'>

                    <h3>Create a new account</h3>
                    <div className='signup-box-content'>
                      <div className='google-button' onClick={() => login()}>
                    
                        <img className='google-logo' src={GoogleLogo} alt='Google logo' />
                        <p className='google-text'>Sign up with Google</p>
                      </div>
                      <div className='or-container'>
                        <div className='or-div'/><p>or</p><div className='or-div'/>
                        </div> 
                      <form className='signup-form' onSubmit={handleSignupSubmit}>
                          <div className='signup-form-name'>

                          <input  placeholder='First name*' onChange={handleFirstNameChange}></input>
                          <input placeholder='Last name*' onChange={handleLastNameChange}></input>
                          </div>
                          <input style={{ border: isValid ? 'initial' : 'solid', borderWidth: isValid ? 'initial' : '.2px', borderColor: isValid ? 'initial' : 'red' }} placeholder='Email address*' onChange={handleOverlayEmailChange}></input>
                          {!isValid && <p className='password-notice'>Email is invalid</p>}
                          <input type="password" placeholder='Password*' onChange={handlePasswordChange}></input>

                          {!passwordMeetsRequirements &&
                          <p className='password-notice'>Minimum requirements: 12 characters long, includes 2 numbers and 2 special characters (e.g., !, @, #, $, etc.) </p>
                        }
                          <input type="password" placeholder='Confirm password*' onChange={handleConfirmPasswordChange}></input>
                          {!passwordsMatch && 
                          <p className='password-notice'>Password does not match</p>
                          }
                          <button style={{ opacity: disableSignupButton ? 0.5 : "inherit", cursor: disableSignupButton ? "default" : "pointer"}} disabled={disableSignupButton} className='signup-button'>Sign up</button>
                      </form>
                      <div className='switch-screen'>
                          <p className='switch-q'>Already have an account?</p>
                          <a href='/affiliates/login'><p className='switch-a'>Log in</p></a>
                      </div>
                    </div>
                </div>}
                
                
                {verificationError ? <div className='error-box'>

                  <div className='error-box-text'>
                    <p>Incorrect verification code! Having issues?</p>
                  <a href='https://discord.com/invite/MMFztRAx4s' className='error-click'>Contact us!</a>
                  </div>
                  
                
                  <div className='error-x'><img onClick={() => {setVerificationError(false)}} src={close}/></div></div> : <div></div>}
                
            </body>
            
        </div>
    );
}

const VerificationInput = () => {
  const [code, setCode] = useState(Array(6).fill('')); // Replace 4 with the number of digits you need
  const [verificationCode, setVerificationCode] = useState(null);

  useEffect(() => {
    // This effect runs whenever the `code` array changes.
    // It combines the array into a string and then converts it to an integer.
    const currentCode = code.join('');
    if (/^\d{6}$/.test(currentCode)) { // Check if all entries are digits and the code is complete
      setVerificationCode(parseInt(currentCode, 10));
      //console.log(currentCode)
    }
  }, [code]); // Dependency array, re-run effect when `code` changes

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^\d$/.test(value) || value === '') {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Focus next input on entry
      if (element.nextSibling && value) {
        element.nextSibling.focus();
      }
    }
  };

  const handleKeyUp = (element, index) => {
    if (element.keyCode === 8 && !element.value && index !== 0) {
      // Focus previous input on backspace if current is empty
      element.target.previousSibling.focus();
    }
  };

  return (
    <div>
      <div>
        {code.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(e.target, index)}
            onKeyUp={(e) => handleKeyUp(e, index)}
            style={{ height: '2vw', width: '2vw', textAlign: 'center', marginRight: '0.5rem', backgroundColor: '#151515', border: "1px solid #848484", borderRadius: "4px", color: "white", opacity: ".7"}}
          />
        ))}
      </div>
    </div>
  );
};

export default Signup;
