import React, { useState, useEffect, useRef } from 'react';
import logo from './assets/sharemate.svg';
import mockup from './assets/mockup.png';
import axios from 'axios';
import './App.css';
import discord from "./assets/discord.png";
import x from "./assets/x.png";
import insta from "./assets/insta.png";
import yt from "./assets/yt.png";
import close from "./assets/close.png";
import phone1 from "./assets/phone1.png";
import phone2 from "./assets/phone2.png";
import phone3 from "./assets/phone3.png";
import caret from "./assets/caret.png";
import caretUp from "./assets/caretUp.png";

import lightIcon from "./assets/icon.ico";
import darkIcon from "./assets/icon_dark.ico";

import Checkbox from './components/Checkbox';
import CaretDown from "./assets/caretDown.png"
import CheckIcon from "./assets/checkIcon.svg"
// import './LiteYouTubeEmbed.css'; // Make sure to create this CSS file

// A single FAQ item component
const FAQItem = ({ question, answer, number }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <button className={"faq-question"+ number} onClick={toggle}>
        {question}
        <img src={isOpen ? caretUp : caret}></img>
      </button>
      {isOpen && <div className={"faq-answer"}>{answer}</div>}
    </div>
  );
};

// The main FAQ component
const FAQ = ({ faqs }) => {
  return (
    <div className="faq-section" >
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} number={index} />
      ))}
    </div>
  );
};





const App = ({ referralCode }) => {
  const [membersCount, setMembersCount] = useState(10);
  const [overlay, setOverlay] = useState(false);
  const overlayRef = useRef(null);
  const overlayEmailRef = useRef(null);
  const [overlayEmail, setOverlayEmail] = useState(false)

  
  const [buttonText, setButtonText] = useState("Stay updated! ");

  const [email, setEmail] = useState('');

  const [price, setPrice] = useState(45)

  const [checkboxHosting, setCheckboxHosting] = useState(false);
  const [checkboxSearching, setCheckboxSearching] = useState(false);

  useEffect(() => {
    
  }, [checkboxHosting, checkboxSearching])
  // Function to update checkboxHosting state
  const toggleCheckboxHosting = () => {
    setCheckboxHosting(!checkboxHosting);
  };

  // Function to update checkboxSearching state
  const toggleCheckboxSearching = () => {
    setCheckboxSearching(!checkboxSearching);
  };


  const faqs = [
    {
      question: 'What exactly is Sharemate?',
      answer: `Sharemate is an upcoming open-market platform for roommates and communities of similar interest to get together. We want to provide flexibility to match your specific use-case and for how long you can stay.`,
    },
    {
      question: 'What is the hosting process like?',
      answer: 'The hosting process involves setting up your space, creating a listing, and accepting roommates. Fees will incur when applicable.',
    },
    {
      question: 'In which countries will Sharemate be available?',
      answer: 'We are committed to being available internationally. We are working on expanding to your country as soon as possible.',
    },
    {
      question: 'Can I host more than one room at once?',
      answer: 'Yes, you can as many rooms as you\'d like. Additionally, you can host couches and mattresses as long as they are in decent condition.',
    },
    // ... add more FAQs here
  ];

  useEffect(() => {
    const fetchMembersCount = () => {
      axios.get('https://mailer.novexa.app/members-count')
        // axios.get('http://localhost:3001/members-count')
        .then(response => {
          // Assuming the server response contains the count in `memberCount` property
          setMembersCount(response.data.memberCount);
        })
        .catch(error => {
          console.error('There was an error fetching the members count!', error);
        });
    };

    // Fetch the count immediately on component mount and then set up the interval
    fetchMembersCount();
    const intervalId = setInterval(fetchMembersCount, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [membersCount, buttonText, email]);


  useEffect(() => {
    // Function to update favicon based on theme
    const changeFavicon = (theme) => {
      const linkForFavicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
      linkForFavicon.type = 'image/x-icon';
      linkForFavicon.rel = 'shortcut icon';
      linkForFavicon.href = theme === 'dark' ? lightIcon : darkIcon;
      document.getElementsByTagName('head')[0].appendChild(linkForFavicon);
    };

    // Function to update theme
    const updateTheme = () => {
      const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      changeFavicon(theme);
    };

    // Update theme on component mount
    updateTheme();

    // Listen for changes in the prefers-color-scheme setting
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme);

    //console.log("referral code from prop: " + referralCode)
    // Cleanup listener on component unmount
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateTheme);
    };

  }, [referralCode]);

  const [isOrderButtonDisabled, setIsOrderButtonDisabled] = useState(true);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Replace 'http://your-backend-endpoint.com/subscribe' with your actual backend endpoint
      // const response = await axios.post('https://mailer.novexa.app/subscribe', { email: email });
      // const response = await axios.post('http://localhost:3001/subscribe', { email: email });
      //console.log(response.data);
      // Handle success (clear the input field, show a success message, etc.)
      setEmail('');
      setButtonText('  Email added!  ');

      await new Promise(resolve => setTimeout(resolve, 2000));

      setButtonText('Stay updated!')


    } catch (error) {
      console.error('There was an error!', error);
      if (error.code == "ERR_BAD_RESPONSE") {
        setButtonText('You\'re already in!');

        await new Promise(resolve => setTimeout(resolve, 6000));
      }



      setButtonText('Stay updated!')


      // Handle error (show error message)
    }
  };
  const [showPopup, setShowPopup] = useState(false);


  // useEffect(() => {
  //   //console.log(overlayRef)
  //   function handleClickOutside(event) {
  //     if (overlayRef.current && !overlayRef.current.contains(event.target)) {
  //       setOverlay(!overlay); // Close the overlay
  //       //("overlay closed")
  //     }

  //     if (overlayEmailRef.current && !overlayEmailRef.current.contains(event.target)) {
  //       setShowPopup(!showPopup); // Close the overlay
  //       //("overlay closed")
  //     }
  //   }

    

  //   // Step 2: Add event listener
  //   document.addEventListener('mousedown', handleClickOutside);

  //   // Step 4: Cleanup
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [overlayRef, overlay, overlayEmail, overlayEmailRef]); // Dependency array ensures effect is only run on mount and unmount


  const [selectedOption, setSelectedOption] = useState('option2'); // Default value
  const [selectedNumber, setSelectedNumber] = useState(1); // Default value
  const [selectedHiddenNumber, setSelectedHiddenNumber] = useState(1); // Default value
  const [freeMonths, setFreeMonths] = useState(6)
  const [phoneCode, setPhoneCode] = useState(1)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [message, setMessage] = useState(null)
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [priceOff, setPriceOff] = useState(200)
  const [sendButtonValue, setSendButtonValue] = useState("Send message")

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value)
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value)
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value)
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value)
    setIsPhoneValid(isValidPhoneNumber(phoneNumber));
    //(event.target.value)

    if (event.target.value == null || event.target.value == '' ) {
      setIsPhoneValid(true)
    }
  };
  const [isValid, setIsValid] = useState(true);


  const handleOverlayEmailChange = (event) => {
    setOverlayEmail(event.target.value)
    setIsValid(isValidEmail(event.target.value));

    if (event.target.value == null) {
      setIsValid(true)
    }
  };

  const handlePhoneCodeChange = (event) => {
    setPhoneCode(event.target.value);

    // (event.target.value)
    // (selectedOption)

  };

  const handleNumberChange = (event) => {
    //(selectedNumber)
    setSelectedNumber(event.target.value);

    //(event.target.value)
    if (event.target.value == '') {
      setFreeMonths(6)
      setSelectedHiddenNumber(true)
    } else{
      setSelectedHiddenNumber(false)

      setSelectedNumber(event.target.value);
    }

  };

  useEffect(() => {
    if (phoneCode !== '' && phoneNumber !== null && overlayEmail !== null && firstName !== null && lastName !== null) {
      setIsOrderButtonDisabled(false)
    } else {
      setIsOrderButtonDisabled(true)
    }
    
  }, [phoneCode, phoneNumber, overlayEmail, firstName, lastName, isPhoneValid])

  useEffect(() => {
    if (selectedOption != "option1") {
      setSelectedNumber(1)
      // (selectedNumber)
    } else {
      setSelectedNumber(1)
      // (selectedNumber)
    }
  }, [selectedOption])

  useEffect(() => {
    if (selectedOption == "option1") {
      setPrice(540 * parseInt(selectedNumber, 10))

      

        if (selectedNumber == 1 || selectedNumber == 2) {

          setFreeMonths(6);
          setPriceOff(200)
        } else if (selectedNumber == 3) {
          
          setFreeMonths(9);
          setPriceOff(300)
          //("set")
        } else if (selectedNumber == 4) {
          
          setFreeMonths(12);
          setPriceOff(400)
        } else if (selectedNumber == 5) {

          setFreeMonths(15);
          setPriceOff(750)
        }
    } else {
      setPrice(45 * parseInt(selectedNumber, 10))

      

      if (selectedHiddenNumber == true) {
        setFreeMonths(6)
        setPriceOff(200)
      } else if (selectedNumber >= 12 && selectedNumber < 36) {
        setFreeMonths(6)
        setPriceOff(200)
      } else if (selectedNumber >= 36 && selectedNumber < 48) {
        setFreeMonths(9)
        setPriceOff(300)
      } else if (selectedNumber >= 48 && selectedNumber < 60) {
        setFreeMonths(12)
        setPriceOff(400)
      } else if (selectedNumber >= 60) {
        setFreeMonths(15)
        setPriceOff(750)
      } else if (selectedNumber < 12) {
        setFreeMonths(6)
        setPriceOff(200)
      } 
      if (!selectedNumber) {
        setPrice(540)
      } 
      
    }

    

    
  }, [selectedNumber, selectedOption])


  const handleOrderSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

  
    try {
      const response = await fetch('https://mailer.novexa.app/create-checkout-session', {
      // const response = await fetch('http://localhost:3001/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: overlayEmail,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          countryCode: phoneCode,
          price: price,
          referralCode: referralCode, 
          isHosting: checkboxHosting,
          isSearching: checkboxSearching,
        }),
      });
  
      const sessionData = await response.json();
      
      if (response.ok) {
        //(sessionData)
        // Redirect to Stripe Checkout using the session ID
        const sessionUrl = sessionData.sessionUrl;
        //(sessionUrl)
        window.location.href = sessionUrl;
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error
    }
  };
  

  const handleMessageSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    setSendButtonValue('Message sent!');

      await new Promise(resolve => setTimeout(resolve, 2000));

      setSendButtonValue('Send message')

    try {
      
      // const response = await fetch('https://mailer.novexa.app/message-endpoint', {
      const response = await fetch('https://mailer.novexa.app/message-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, firstName: firstName, lastName: lastName, text: message}),
      });
      

      if (response.ok) {
        const data = await response.json();
        //('Success:', data);
        // Handle success - perhaps redirecting to a Stripe checkout session or displaying a success message
      } else {
        console.error('Server response wasn\'t OK', response);
        // Handle error - the server didn't like the request
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      // Handle network error - the fetch call itself failed
    }


  };

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^\d+$/;
    return regex.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(String(email).toLowerCase());
  };


  const mouseOutTimeoutRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = () => {
            // Hide popup and clear timeout if mouse moves back into viewport
            if (mouseOutTimeoutRef.current) {
                clearTimeout(mouseOutTimeoutRef.current);
                mouseOutTimeoutRef.current = null;
            }
            setShowPopup(false);
        };

        const handleMouseOut = (event) => {
            // Ensure the mouse is actually leaving the window
            if (!event.relatedTarget && !event.toElement) {
                // Start a timeout to show the popup
                mouseOutTimeoutRef.current = setTimeout(() => {
                    setShowPopup(true);
                }, 120); // 3-second delay
            }
        };

        // document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseout', handleMouseOut);

        return () => {
            // document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseout', handleMouseOut);
            if (mouseOutTimeoutRef.current) {
                clearTimeout(mouseOutTimeoutRef.current);
            }
        };
    }, []);

  return (
    <div className="Waitlist">
      {/* {!showPopup  ? <div className='overlay-bg'>

<div ref={overlayEmailRef} className='overlay'>
  <div className='x'><img onClick={() => {setShowPopup(!showPopup)&& (setSelectedNumber(1))}} src={close}/></div>
  <div className='overlay-left'>
    <h2>Don't leave <span className='heading-green'>just yet!</span></h2>
    <p>Get early access, enjoy full benefits, and get free months when you pre-order.</p>
    <div className='contribute'>
      <p className='donation-prompt'>We are doing a crowdfunding campaign!</p><a href='https://donate.stripe.com/4gw14t5Ya83S38kcMQ' > <p className='heading-green'>Back our project --></p>  </a>
      </div>
  </div>
  <div className='overlay-right'>
    <form className='overlay-form' onSubmit={handleOrderSubmit}>
      <div className='form-name'>
        <input placeholder="First name*" onChange={handleFirstNameChange} />
        <input placeholder="Last name*" onChange={handleLastNameChange}/>
      </div>
      <input style={{ border: isValid ? 'initial' : 'solid', borderWidth: isValid ? 'initial' : '.2px', borderColor: isValid ? 'initial' : 'red' }} placeholder="Email address*" onChange={handleOverlayEmailChange}/>
      <div className='overlay-phone'>
      <input className='phone-code' onChange={handlePhoneCodeChange} placeholder="+1" />
      <input style={{ border: isPhoneValid ? 'initial' : 'solid', borderWidth: isPhoneValid ? 'initial' : '.2px', borderColor: isPhoneValid ? 'initial' : 'red' }} className='phone-number' onChange={handlePhoneNumberChange} placeholder="Phone number*" />
      </div>
      <div className='form-time'>
        <div className='form-time-inputs'>
          { selectedOption == "option1" ?
          
        <select value={selectedNumber} id="simple-dropdown" name="options" onChange={handleNumberChange}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          :
          <input value={selectedNumber > 60 ? setSelectedNumber(60) : selectedNumber} id="simple-dropdown" name="options" placeholder='12' onChange={handleNumberChange}>
            
          </input>
        }
          <select value={selectedOption} id="simple-dropdown" name="options" onChange={handleSelectChange}>
            <option value="option1">Years</option>
            <option value="option2">Months</option>
          </select>
        </div>
        {price > 539 ? 
                  <div className='overlay-prices'>
                  <p className='old-price'>${priceOff} OFF <span className='old-price-off'>${price} USD</span></p>
                  <div className='form-price'>
                  <p>${(price > 539 ? price - priceOff : price).toLocaleString()} USD</p>
                  
                  <p className='months-free'>(+{freeMonths} months free*)</p>
                  </div>

                  </div>
                  :<div className='overlay-prices'>
                  <div className='form-price'>
                  <p>${(price).toLocaleString()} USD</p>
                  
                  <p className='months-free'>(+{freeMonths} months free*)</p>
                  </div>

                  </div>}
      </div>

      <div className='overlay-checkboxes'>
      <Checkbox isHost={true} toggleCheckboxHosting={toggleCheckboxHosting}/>
      <Checkbox isHost={false} toggleCheckboxSearching={toggleCheckboxSearching}/>

      </div>
      
      <div className='divider'></div>
      <input style={{ opacity: isOrderButtonDisabled ? 0.5 : 1, cursor: isOrderButtonDisabled ? "default" : "pointer"}} disabled={isOrderButtonDisabled} type='submit' id='submit-order' value='Pre-order' />
      <p className='disclaimer'>*Free from monthly and transactional fees when using our services. Does not include housing costs. Pre-orders are non-refundable.</p>
    </form>
  </div>
</div>
</div> : <></>} */}
      {overlay ?
        <div className='overlay-bg'>

          <div ref={overlayRef} className='overlay'>
            <div className='x'><img alt='close button' onClick={() => {setOverlay(!overlay)&& (setSelectedNumber(1))}} src={close}/></div>
            <div className='overlay-left'>
              <div className='overlay-left-top'>

              <h2>Thank you for choosing <span className='heading-green'>Sharemate</span></h2>
              <p>Get early access, access all features, and get free months when you pre-order. Buy 1 month, get 6 free.</p>
              </div>
              <div className='contribute'>
                
                <p className='donation-prompt'>We are also receiving donations!</p><a href='https://donate.stripe.com/4gw14t5Ya83S38kcMQ' > <p className='heading-green'>Back our project --></p>  </a>
                </div>
              {/* <a>See all benefits</a> */}
            </div>
            <div className='overlay-right'>
              <form className='overlay-form' onSubmit={handleOrderSubmit}>
                <div className='form-name'>
                  <input placeholder="First name*" onChange={handleFirstNameChange} />
                  <input placeholder="Last name*" onChange={handleLastNameChange}/>
                </div>
                <input style={{ border: isValid ? 'initial' : 'solid', borderWidth: isValid ? 'initial' : '.2px', borderColor: isValid ? 'initial' : 'red' }} placeholder="Email address*" onChange={handleOverlayEmailChange}/>
                <div className='overlay-phone'>
                <input className='phone-code' onChange={handlePhoneCodeChange} placeholder="+1" />
                <input style={{ border: isPhoneValid ? 'initial' : 'solid', borderWidth: isPhoneValid ? 'initial' : '.2px', borderColor: isPhoneValid ? 'initial' : 'initial' }} className='phone-number' onChange={handlePhoneNumberChange} placeholder="Phone number*" />
                </div>
                <div className='form-time'>
                  <div className='form-time-inputs'>
                    { selectedOption == "option1" ?
                    
                  <select value={selectedNumber} id="simple-dropdown" name="options" onChange={handleNumberChange}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                    :
                    <input value={selectedNumber > 60 ? setSelectedNumber(60) : selectedNumber} id="simple-dropdown" name="options" placeholder='1' onChange={handleNumberChange}>
                      
                    </input>
                  }
                    <select value={selectedOption} id="simple-dropdown" name="options" onChange={handleSelectChange}>
                      <option value="option1">Years </option>
                      <option value="option2">Months</option>
                      
                    </select>
                    {/* <input placeholder="Years" /> */}
                  </div>

                  {price > 539 ? 
                  <div className='overlay-prices'>
                  <p className='old-price'>${priceOff} OFF <span className='old-price-off'>${price} USD</span></p>
                  <div className='form-price'>
        {/* <p>540 USD</p> */}
                  <p className={'total-price', 'old-price'}>${(price > 539 ? price - priceOff : price).toLocaleString()} USD</p>
                  
                  <p className='months-free'>+{freeMonths} months free*</p>
                  </div>

                  </div>
                  :<div className='overlay-prices'>
                  <div className='form-price'>
        {/* <p>540 USD</p> */}
                  <p className={'total-price', 'old-price'}>${(price).toLocaleString()} USD</p>
                  
                  <p className='months-free'>+{freeMonths} months free*</p>
                  </div>

                  </div>}
                  
                </div>
                <div className='overlay-checkboxes'>
                <Checkbox isHost={true} toggleCheckboxHosting={toggleCheckboxHosting}/>
                <Checkbox isHost={false} toggleCheckboxSearching={toggleCheckboxSearching}/>

                </div>
                
                <div className='divider'></div>
                <input style={{ opacity: isOrderButtonDisabled ? 0.5 : 1, cursor: isOrderButtonDisabled ? "default" : "pointer"}} disabled={isOrderButtonDisabled} type='submit' id='submit-order' value='Pre-order now' />
                <p className='disclaimer'>*Limited time only. Does not include housing costs. Pre-orders are refundable for up to 7 days.</p>
              </form>
            </div>
          </div>
        </div> : <></>}
        <header className='limited-offer'>
          <p className='announcement'><span className='p-bold'>LIMITED TIME ONLY</span> - Pre-order 1 month & get 6 months free today</p><button onClick={ () => setOverlay(!overlay)} className='header-button'>Claim offer</button>
        </header>
        <nav className="Waitlist-header-home">
                <a href='/'>
                    <img className="Sharemate logo with text" src={logo} className="Waitlist-logo"  />
                </a>
                <div className='header-links'>
                    <a href='/vision'><p>Vision</p></a>
                    <a href='/affiliates'><p>Affiliates</p></a>
                    <a href='https://docs.google.com/document/d/1gLYdYDB7G8xBEsFhLlo1TCfG7071bqzyS_ldhCCVJbc/edit?usp=sharing'><p>White Paper</p></a>
                    {/* <a href='/coming-soon'><p>Roadmap</p></a> */}
                    <a href='https://donate.stripe.com/4gw14t5Ya83S38kcMQ'><p>Donate</p></a>
                </div>
                <div className='header-right'>

                    <button className='header-button' onClick={() => setOverlay(!overlay)}>Pre-order</button>
                </div>
            </nav>
      <body className='Waitlist-body'>
              <div className='waitlist-sections'>
        <main className='Waitlist-hero'>
          <section className='Waitlist-text'>

            <section className='Waitlist-text-top'>
              <h1>The world's most flexible <span className='heading-green'>roommate app</span></h1>
              {/* <div className='progress-bar'><div className='progress-bar-g'></div></div>
              <p className='amount'>$0 out of $5,000 raised.  </p> */}

              <summary className='not-amount'>Sharemate lets you live with like-minded roommates or communities and earn when you host living space. Available fall 2024. </summary>
            </section>
            <section className='Waitlist-text-bottom'>
              <button id='submit' onClick={() => { setOverlay(!overlay) }}>Pre-order</button>
              <p>Earn up to 30% on commissions through our affiliate program. <a className='get-started' href='/affiliates'><span className='text-green'> Get started --></span></a></p>
            </section>

          </section>
          <section className='Waitlist-image'>
          <LiteYouTubeEmbed className="youtube-embed" videoId="jbzgs7_vhx4" />
            {/* <iframe 
              src="https://www.youtube.com/embed/jbzgs7_vhx4?rel=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"
              allowfullscreen
            /> */}
          </section>
        </main>

        <section className='section1'>
          <img alt='Earnings page for tracking roommate earnings in Sharemate App.' src={phone1}/>
          <div className='section-div'>
          <h2>A new opportunity to <span className='heading-green'>save</span></h2>
          <p>Live with as many roommates as you'd like. Whether it’s long or short term, Sharemate will give you flexibility to host and request roommates at any time frame.</p>

          </div>
        </section>
        <section className='section2'>
          <div className='section-div'>
          <h2>Move into communities that you’ll <span className='heading-green'>love</span></h2>
          <p>Join clubs, communities, and meet people with similar interests to collaborate, hang out, strengthen your network and share living space together.</p>

          </div>
          <img alt='Sharemate listing page on mobile. Used for people looking for rooms to live in.' src={phone2}/>

        </section>
        <section className='section1'>
        <img alt='Map mockup for finding roomamtes in Sharemate' src={phone3}/>
          <div className='section-div'>
          <h2>Find the <span className='heading-green'>right</span> people near you</h2>
          <p>Whether you want to meet friends or relevant people you’re interested in, Sharemate makes it easy.</p>

          </div>
        </section>

        </div>

        <section className='pricing-section'>


        <div className='Pricing'>
          <h2>Pricing</h2>
          <div className='Pricing-container'>
            <div className='Pricing-item'>
              <div className='Pricing-item-top'>

              <h3>Full Access</h3>
              <h5>For both hosts and tenants</h5>
              </div>
              <h4>$45 USD/mo</h4>
              <div className='Pricing-benefits'>
              <div className='Pricing-benefit'>
                  <img src={CheckIcon}/>
                  <p>Live with like-minded roommates</p>
                </div>
                <div className='Pricing-benefit'>
                  <img src={CheckIcon}/>
                  <p>List unlimited homes and host to unlimited roommates</p>
                </div>
                <div className='Pricing-benefit'>
                  <img src={CheckIcon}/>
                  <p>Unlock in-app messaging features to roommates</p>
                </div>
                <div className='Pricing-benefit'>
                  <img src={CheckIcon}/>
                  <p>Request a location to live in at any time</p>
                </div>
                <div className='Pricing-benefit'>
                  <img src={CheckIcon}/>
                  <p>Stay in locations for a flexible time frame</p>
                </div>
                <div className='Pricing-benefit'>
                  <img src={CheckIcon}/>
                  <p>Unlock all features Sharemate has to offer</p>
                </div>
                <div className='Pricing-benefit'>
                  <img src={CheckIcon}/>
                  <p>Pre-order and get 6 months free!</p>
                </div>
              </div>
              <button id='submit' onClick={() => { setOverlay(!overlay) }}>Pre-order</button>
            </div>
          </div>
        </div>

        </section>

        <div className='faq'>

          <div className='faq-container' >
            <h2>FAQ</h2>
            <FAQ faqs={faqs} />
            <p>For complete information about Sharemate, <a className='faq-link' href='https://docs.google.com/document/d/1gLYdYDB7G8xBEsFhLlo1TCfG7071bqzyS_ldhCCVJbc/edit?usp=sharing'>read our white paper --></a></p>
            </div>
          <div />
        </div>

        

        <div className='subscribe-newsletter'>
          <div className='newsletter-text'>

          <h2>Subscribe to our newsletter</h2>
        <p>Stay ahead of the curve with the latest updates, opportunities, and valuable information to expand your knowledge base.</p>
          </div>
        <form className='Waitlist-newsletter' onSubmit={handleSubmit}>
          <input
            type='email'
            id='email'
            name='email'
            placeholder='Enter your email address'
            value={email}
            onChange={handleEmailChange}
            required
          />
          <input type='submit' id='submit' value={buttonText} />
        </form>
        </div>
        

        <div className='contact-us-container'>

        
        <div className='contact-us'>
          <h2>Contact Us</h2>
        <form onSubmit={handleMessageSubmit}>
          <div className='contact-name'>
            
          <input
            placeholder="First name*"
            value={firstName}
            onChange={handleFirstNameChange}
            required
            />
          <input
            placeholder="Last name*"
            value={lastName}
            onChange={handleLastNameChange}
            required
            />
            </div>

            <div className='contact-email'>
              <input
            type='email'
            name='email'
            placeholder='Enter your email address*'
            value={email}
            onChange={handleEmailChange}
            required
          />
          <div className='contact-message'>
            <textarea
            placeholder='Write us a message...'
            value={message}
            onChange={handleMessageChange}
            required
            />
            </div>
          </div>
          
          <div className='divider' />
          <div className='contact-send'>
            <p>We will reach out to you via email as soon as possible</p>
          <input type='submit' id='submit' value={sendButtonValue} />
          </div>
          
        </form>
        </div>
        </div>
        <div />


      </body>
      <footer className='Waitlist-footer'>
            <div className='footer-top-div'/>

                <div className='Waitlist-footer-top'>
                    <div className='footer-logo'>
                            <img src={logo} className="Waitlist-logo" alt="logo" />
                    </div>
                    <div className='waitlist-footer-links'>

                        <section className='link-column'>
                            <h3>Discover</h3>
                            <p><a href='/vision'>Vision</a></p>
                            <p><a href='/coming-soon'>Roadmap</a></p>
                            <p><a href='https://docs.google.com/document/d/1gLYdYDB7G8xBEsFhLlo1TCfG7071bqzyS_ldhCCVJbc/edit?usp=sharing'>White paper</a></p>
                            <p><a href='/affiliates'>Affiliates</a></p>
                            <p><a href='/coming-soon'>About us</a></p>
                        </section>
                        <section className='link-column'>
                            <h3>Community</h3>
                            <p><a href='https://discord.gg/MMFztRAx4s'>Join us on Discord</a></p>
                            <p><a href='https://discord.gg/jr2RzpSduP'>Ambassadors</a></p>
                            <p><a href='https://discord.gg/pr527aXuRM'>Affiliate club</a></p>
                            <p><a href='/coming-soon'>Forums</a></p>
                        </section>
                        <section className='link-column'>
                            <h3>Help</h3>
                            <p><a href='https://discord.gg/WFAM5QvRDy'>Learn Sharemate</a></p>
                            <p><a href='https://discord.gg/WFAM5QvRDy'>Request a feature</a></p>
                            <p><a href='https://discord.gg/WFAM5QvRDy'>Submit feedback</a></p>
                            <p><a href='https://discord.gg/WFAM5QvRDy'>Report a bug</a></p>
                            <p><a href='https://discord.gg/WFAM5QvRDy'>Support</a></p>
                        </section>
                        <section className='link-column'>
                            <h3>Policies</h3>
                            <p><a href="https://docs.google.com/document/d/1fc04Swmr1peTqOkI3DkkpfNB6he5j8KVarCFTwtj_Sc/edit?usp=sharing">Terms of Service</a></p>
                            <p><a href="https://docs.google.com/document/d/1iF3LC5JIqyU57Nh6qppZETFCw_inxn4ePgYCbzNpyug/edit?usp=sharing">Privacy Policy</a></p>
                            <p><a href='/coming-soon'>Brand Guidelines</a></p>
                            <p><a href='/coming-soon'>Licenses</a></p>
                        </section>
                    </div>
                </div>
                <div className='footer-div'/>
                <div className='Waitlist-footer-bottom'>
                    <div className='copyright-notice'>
                        <p>&copy; 2024 Novexa. All Rights Reserved.</p>
                    </div>
                    <div className='socials'>
                    <a href="https://discord.gg/8ZFnnuWrpQ"><img src={discord} /></a>
                    <a href="https://twitter.com/SharemateApp"><img src={x} /></a>
                    <a href="https://www.instagram.com/sharemateapp"><img src={insta} /></a>
                    <a href="https://www.youtube.com/channel/UC2omiezKZ7OI7xjBWlZjZoA"><img src={yt} /></a>
                    </div>
                </div>
            </footer>
    </div>
  );
}

const LiteYouTubeEmbed = ({ videoId }) => {
    const [loaded, setLoaded] = useState(false);

    const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&showinfo=0`;

    const handleLoadVideo = () => setLoaded(true);

    return (
        <div className="youtube-lite" onClick={handleLoadVideo}>
            {!loaded && (
                <>
                <img src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}/>
                <div className="play-button"></div>
                </>
            )}
            {loaded && (
                <iframe
                    width="560"
                    height="315"
                    src={videoUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube video player"
                ></iframe>
            )}
        </div>
    );
};


export default App;
